import React, { useCallback, useEffect, useState } from "react";
import CenterLogo from "../../assets/images/registration/npoker_logo.png";
import { POST } from "helpers/api";
import { isMobile } from "react-device-detect";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { useVerificationInfoStore } from "store/verificationInfo";

function Registration() {
  const { verificationInfo, setVerificationInfo } = useVerificationInfoStore();
  const [createUser, setCreateUser] = useState({
    userId: "",
    password: "",
    checkpassword: "",
    nickname: "",
    joinSite: "nplayshop",
    name: verificationInfo.name,
    sex: verificationInfo.sex,
    email: "",
    joinIp: "192.01.01.1",
    phone: verificationInfo.phone,
    phoneAuth: true,
    CI: verificationInfo.ci,
    DI: verificationInfo.di,
    birthday: verificationInfo.dob,
    device: "",
    DIRoute: "PHONE",
    referral_code: "",
  });

  const navigate = useNavigate();
  const [checkUserId, setCheckUserId] = useState(false);
  const [checkRefferalCode, setCheckRefferalCode] = useState("");
  const [checkUserNickname, setCheckUserNickname] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const validate = (e) => {
    var createUserCopy = { ...createUser };
    createUserCopy.password = e;
    setCreateUser(createUserCopy);
    if (
      validator.isStrongPassword(createUser.password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
        maxLength: 15,
      })
    ) {
      setErrorMessage(false);
    } else {
      setErrorMessage(true);
    }
  };

  const checkId = useCallback(async () => {
    var sendData = {
      username: createUser.userId,
    };
    const response = await POST("/user/check-username", sendData, {
      loader: true,
    });
    if ((response.code !== 200)) {
      setCheckUserId(true);
    } else {
      setCheckUserId(false);
    }
  }, [createUser]);

  async function checkDevice() {
    var createUserCopy = { ...createUser };
    if (isMobile) {
      createUserCopy.device = "mobile";
    } else {
      createUserCopy.device = "pc";
    }
    setCreateUser(createUserCopy);
  }
  async function checkNickname() {
    var sendData = {
      nickname: createUser.nickname,
    };

    const response = await POST("/user/check-nickname", sendData, {
      loader: true,
    });
    setCheckUserNickname(response);
  }
  function showErrorAlert() {
    if (checkUserId === true) {
      return "d-block";
    } else if (createUser.userId === "") {
      return "d-none";
    } else if (checkUserId === false) {
      return "d-none";
    } else {
      return "d-none";
    }
  }

  async function handleCreateMember() {
    var sendData = {
      username: createUser.userId,
      password: createUser.password,
      nickname: createUser.nickname,
      joinSite: "nplayshop",
      joinIp: createUser.joinIp,
      name: createUser.name,
      sex: createUser.sex,
      email: createUser.email,
      phoneNumber: createUser.phone,
      phoneAuth: createUser.phoneAuth,
      ci: createUser.CI,
      di: createUser.DI,
      birthday: createUser.birthday,
      device: createUser.device,
      diRoute: createUser.DIRoute,
      referralCode: createUser.referral_code
    };

    const response = await POST("/user/join", sendData, {
      loader: true,
    });
    if (response.code === 200) {
      navigate("/login");
    }
    if (response.message === "REFERRAL_CODE_NOT_FOUND") {
      setCheckRefferalCode("d-block");
    } else {
      setCheckRefferalCode("");
    }
  }
  useEffect(() => {
    checkDevice();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className=" gameFi_upper_dark_bg">
        <section>
          <div className="container">
            <div className="row">
              <div className="registration_page_top">
                <img src={CenterLogo} alt="" />
                <p className="registration_title">가입정보를 입력해주세요.</p>
              </div>
              <div className="col-lg-6 mx-auto registration_page">
                <div className="form-group">
                  <div>
                    <label>아이디</label>
                    <div className="form_button">
                      <input
                        type="text"
                        className="form-control"
                        name="en"
                        placeholder="영어로 시작하고, 영어와 숫자를 조합한 6~20자리 아이디를 입력해주세요."
                        value={createUser.userId}
                        onChange={(e) =>
                          setCreateUser({
                            ...createUser,
                            userId: e.target.value,
                          })
                        }
                      />

                      <button onClick={() => checkId()} className="check_btn">
                        중복확인
                      </button>
                    </div>
                  </div>
                  <span className={`alert_message ${showErrorAlert()}`}>
                    ※ 사용 불가능한 아이디입니다.
                  </span>
                </div>

                <div className="form-group">
                  <div className="position-relative">
                    <label>비밀번호</label>
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      name="en"
                      placeholder="8-15자리, 영문, 대문자, 소문자, 숫자, 특수기호를 조합한 비밀번호를 입력해주세요. "
                      value={createUser.password}
                      onChange={(e) => validate(e.target.value)}
                    />
                    <FaEye
                      className="pass_show"
                      onClick={() => setShowPassword((prev) => !prev)}
                    />
                  </div>
                  {errorMessage && (
                    <span className="alert_message d-block">
                      8-15자리, 영문, 숫자, 특수기호를 조합해주십시오.
                    </span>
                  )}
                </div>
                <div className="form-group">
                  <div>
                    <label>비밀번호 확인 </label>
                    <input
                      type="password"
                      className="form-control"
                      name="en"
                      placeholder="비밀번호 확인  "
                      value={createUser.checkpassword}
                      onChange={(e) =>
                        setCreateUser({
                          ...createUser,
                          checkpassword: e.target.value,
                        })
                      }
                    />
                  </div>
                  {createUser?.password !== createUser?.checkpassword && (
                    <span className="alert_message d-block">
                      ※ 비밀번호 조건에 부합하지 않습니다
                    </span>
                  )}
                </div>
                <div className="form-group">
                  <div>
                    <label>닉네임</label>
                    <div className="form_button">
                      <input
                        type="text"
                        className="form-control"
                        name="en"
                        placeholder="닉네임 (한글2~8글자, 영문 4~16자)"
                        value={createUser.nickname}
                        onChange={(e) =>
                          setCreateUser({
                            ...createUser,
                            nickname: e.target.value,
                          })
                        }
                      />

                      <button
                        onClick={() => checkNickname()}
                        className="check_btn"
                      >
                        중복확인
                      </button>
                    </div>
                  </div>
                  {checkUserNickname?.data === null && (
                    <span className="alert_message d-block">
                      ※ 닉네임 조건에 부합하지 않습니다.
                    </span>
                  )}
                </div>

                <div className="form-group">
                  <div>
                    <label>이메일</label>
                    <input
                      type="text"
                      className="form-control"
                      name="en"
                      placeholder="이메일 주소를 입력해주세요"
                      value={createUser.email}
                      onChange={(e) =>
                        setCreateUser({
                          ...createUser,
                          email: e.target.value,
                        })
                      }
                    />
                  </div>
                  <span className="alert_message">
                    ※ 이메일 형식에 부합하지 않습니다.
                  </span>
                </div>
                <div className="form-group">
                  <div>
                    <label>가입 코드</label>
                    <input
                      type="text"
                      className="form-control"
                      name="en"
                      placeholder="가입 코드를 입력해주세요  "
                      value={createUser.referral_code}
                      onChange={(e) =>
                        setCreateUser({
                          ...createUser,
                          referral_code: e.target.value,
                        })
                      }
                    />
                  </div>
                  <span className={`alert_message ${checkRefferalCode}`}>
                    ※ 존재하지 않는 가입 코드입니다
                  </span>
                  <span className="alert_message"></span>
                </div>

                <button
                  className="enter_btn"
                  onClick={() => handleCreateMember()}
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Registration;
