import React, { useState } from "react";
import CenterLogo from "../../assets/images/registration/npoker_logo.png";
import { POST } from "helpers/api";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { clearTemporaryStorage } from 'helpers/helpers'
function Registration() {
  const [user, setUser] = useState({ "username": "", "password": "" })
  const [loginFail, setLoginFail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

 
	async function handleSubmit(e) {
		var sendData = {
			username: user.username,
			password: user.password
		}
		clearTemporaryStorage()
		const response = await POST('/user/login', sendData, { loader: true })
		if (response.code === 200 ) {
			localStorage.setItem("username", user.username.toLowerCase());
			localStorage.setItem('token', response.data.token)
			localStorage.setItem("authUser", JSON.stringify(user))
      navigate("/");
		}
		else{
      setLoginFail(true);
		} 
	}


  return (
    <>
      <div className=" gameFi_upper_dark_bg">
        <section>
          <div className="container">
            <div className="row">
              <div className="registration_page_top">
                <img src={CenterLogo} alt="" />
                <p className="registration_title">엔포커 로그인</p>
              
              </div>
              <div className="col-lg-6 mx-auto registration_page">
              {loginFail && (
                    <div className="login_fail">
                      <h5>로그인에 실패하였습니다.</h5>
                      아이디/비밀번호 확인 후 다시 로그인해주세요.
                    </div>
                  )}
                <div className="form-group">
                  <div>
                    <label>아이디</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="아이디를 입력해주세요. "
                      autoFocus	
											name="username"
											value={user.username}
											onChange={(e) => setUser({ ...user, 'username': e.target.value })}
                    />
                  </div>
                  {/* <span className={`alert_message ${showErrorAlert()}`}>
                    ※ 사용 불가능한 아이디입니다.
                  </span> */}
                </div>

                <div className="form-group">
                  <div className="position-relative">
                    <label>비밀번호</label>
                    <input
                      type={showPassword ? "text" : "password"}
											name="password"
                      placeholder="비밀번호를 입력해주세요."
                      className="form-control"
											value={user.password}
											onChange={(e) => setUser({ ...user, 'password': e.target.value })}
                      // onChange={(e) => validate(e.target.value)}
                    />
                    <FaEye
                      className="pass_show"
                      onClick={() => setShowPassword((prev) => !prev)}
                    />
                  </div>
                </div>
                <div className="flex_end">
                  <a href="/forgot" className="forgot">
                    아이디/비밀번호 찾기
                  </a>
                </div>
                <button
                  className="enter_btn"
                  onClick={(e) => handleSubmit()}
                >
                  로그인
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Registration;
