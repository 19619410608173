import React, { useState, useEffect, useCallback } from "react";
import { FaEye } from "react-icons/fa";
import validator from "validator";
import { POST } from "helpers/api";
import { clearTemporaryStorage } from "helpers/helpers";
import CenterLogo from "../../assets/images/registration/npoker_logo.png";
import { useNavigate } from "react-router-dom";

function ChangePassword() {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const validate = (str) => {
    setNewPassword(str);
    if (
      validator.isStrongPassword(str, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
        maxLength: 15,
      })
    ) {
      setErrorMessage(false);
    } else {
      setErrorMessage(true);
    }
  };

  const handleChangePassword = useCallback(() => {
    if (newPassword !== confirmPassword) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }

    const sendData = {
      password: password,
      newPassword: newPassword,
    };

    POST("/user/change-password", sendData, { loader: true }).then(
      (response) => {
        if (response.code === 200) {
          alert("비밀번호가 변경되었습니다.");
          setPassword("");
          setNewPassword("");
          setConfirmPassword("");
          clearTemporaryStorage();
          navigate("/");
        } else {
          alert("에러가 발생하였습니다.");
        }
      }
    );
  }, [password, newPassword, confirmPassword]);

  useEffect(() => {
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className=" gameFi_upper_dark_bg">
        <section style={{ padding: "230px 0" }}>
          <div className="container">
            <div className="row">
              <div className="registration_page_top">
                <img src={CenterLogo} alt="" />
                <p className="registration_title">비밀번호 변경</p>
              </div>
              <div className="col-lg-6 mx-auto change_password_page">
                <div className="form-group">
                  <div className="position-relative">
                    <label>현재 비밀번호</label>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="현재 비밀번호를 입력해주세요."
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <FaEye
                      className="pass_show"
                      onClick={() => setShowPassword((prev) => !prev)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="position-relative">
                    <label>새로운 비밀번호</label>
                    <input
                      type={showNewPassword ? "text" : "password"}
                      name="password"
                      placeholder="8-15자리, 영문, 대문자, 소문자, 숫자, 특수기호를 조합한 비밀번호를 입력해주세요. "
                      className="form-control"
                      value={newPassword}
                      onChange={(e) => validate(e.target.value)}
                    />
                    <FaEye
                      className="pass_show"
                      onClick={() => setShowNewPassword((prev) => !prev)}
                    />
                  </div>
                  {errorMessage && (
                    <span className="alert_message d-block">
                      8-15자리, 영문, 숫자, 특수기호를 조합해주십시오.
                    </span>
                  )}
                </div>
                <div className="form-group">
                  <div className="position-relative">
                    <label>비밀번호 확인</label>
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      name="password"
                      placeholder="비밀번호 확인"
                      className="form-control"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <FaEye
                      className="pass_show"
                      onClick={() => setShowConfirmPassword((prev) => !prev)}
                    />
                  </div>
                </div>
                <button
                  className="enter_btn"
                  onClick={() => handleChangePassword()}
                >
                  비밀번호 변경
                </button>
              </div>
            </div>
          </div>
        </section >
      </div >
    </>
  );
}

export default ChangePassword;
