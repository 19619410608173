import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { defaultVerificationInfo, useVerificationInfoStore } from "store/verificationInfo";
import CenterLogo from "../../assets/images/registration/npoker_logo.png";
import { POST } from "helpers/api";

const UserIdTable = (props) => {
  const { userIds } = props;
  return <table className="table text-white text-center" style={{ borderTop: '1px solid white'}}>
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">아이디</th>
        <th scope="col">가입일</th>
      </tr>
    </thead>
    <tbody>
      {
        userIds.map((userId, idx) => {
          const { username, createdAt } = userId;
          return <tr>
            <td scope="row">{idx + 1}</td>
            <td>{username}</td>
            <td>{dayjs(createdAt).format('YYYY-MM-DD')}</td>
            </tr>;
        })
      }
    </tbody>
  </table>
}

function FindPasswordResult() {
  const navigate = useNavigate();
  const { verificationInfo, clearVerificationInfo } = useVerificationInfoStore();
  const [loading, setLoading] = useState(true);
  const [userIds, setUserIds] = useState([]);
  const [error, setError] = useState(false);

  const toLogin = () => {
    navigate("/login");
  }

  const resetPassword = async () => {
    const { ci, di } = verificationInfo;
    const res = await POST('/user/find-username', {
      ci,
      di,
    });
    setLoading(false);
    if (res.code === 200) {
      clearVerificationInfo();
      setUserIds(res.data.items);
    }
    else {
      setError(true);
    }
  }

  useEffect(() => {
    if (JSON.stringify(verificationInfo) === JSON.stringify(defaultVerificationInfo)) {
      alert("잘못된 접근입니다.");
      navigate("/find-password");
    }
    resetPassword();
  }, []);

  return (
    <>
      <div className="gameFi_upper_dark_bg min-h-100vh ">
        <section>
          <div className="registration_page_top">
            <img src={CenterLogo} alt="" />
            <p className="registration_title">아이디 찾기</p>
          </div>
          <div></div>
          <div className="container">
            {
              loading && (
                <div className="row">
                  <div className="col-lg-6 mx-auto">
                    <div className="text-center">
                      <div className="spinner-border text-light" role="status">
                        <span className="visually-hidden">아이디를 찾는 중입니다...</span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            {
              error ?
                (<div className="row">
                  <div className="col-lg-6 mx-auto">
                    <p className="text-white text-center">
                      문제가 발생하였습니다.
                      <br />
                      고객센터로 문의해주시기 바랍니다.
                    </p>
                  </div>
                </div>)
                :
                (<div className="row">
                  <div className="col-lg-6 mx-auto">
                    <p className="text-white text-center">
                      해당 명의로 가입한 아이디를 {userIds.length}개 찾았습니다.
                    </p>
                    <div className="text-white">
                      <UserIdTable userIds={userIds} />
                    </div>
                    <button onClick={toLogin} className="enter_btn">
                      로그인
                    </button>
                  </div>
                </div>)
            }
          </div>
        </section>
      </div>
    </>
  );
}

export default FindPasswordResult;
