import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CenterLogo from "../../assets/images/registration/npoker_logo.png";

function Forgot() {
  const navigate = useNavigate();

  const clickFindID = () => {
    navigate("/phone-verify/find-id");
  }

  const clickFindPassword = () => {
    navigate("/find-password");
  }

  useEffect(() => {
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className=" gameFi_upper_dark_bg">
        <section style={{ padding: "230px 0" }}>
          <div className="container">
            <div className="row">
              <div className="registration_page_top">
                <img src={CenterLogo} alt="" />
                <p className="registration_title">아이디 / 비밀번호 찾기</p>
              </div>
              <div className="d-flex justify-content-evenly">
                <button
                  className="find_btn"
                  onClick={() => clickFindID()}
                >
                  아이디 찾기
                </button>
                <button
                  className="find_btn"
                  onClick={() => clickFindPassword()}
                >
                  비밀번호 찾기
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Forgot;
