import React, { useRef, useState } from "react";
import CenterLogo from "../../assets/images/registration/npoker_logo.png";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
function ConnectWallet() {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const checkbox = useRef();
  const checkbox2 = useRef();
  const checkbox3 = useRef();
  const checkbox4 = useRef();
  const [modalLogout, setModalLogout] = useState(false);

  const handleClick = () => {
    if (
      checkbox.current.checked &&
      checkbox2.current.checked &&
      checkbox3.current.checked 
    ) {
      navigate("/phone-verify");
    } else {
      setMessage("이용약관 및 개인정보 보호정책에 동의해주세요");
    }
  };

  return (
    <>
      <div className="gameFi_upper_dark_bg min-h-100vh">
        <section>
          <div className="registration_page_top">
            <img src={CenterLogo} alt="" />
            <p className="registration_title">엔포커 약관에 동의해주세요</p>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <div className="terms_conditions">
                  <form>
                    <div className="form-group ">
                      <input type="checkbox" id="html" ref={checkbox} />
                      <label htmlFor="html">
                        <a className="" href="">
                          이용약관
                        </a>
                        및
                        <a className="" href="">
                          개인정보 수집용, 홍보성 정보 수신
                        </a>
                        에 동의합니다
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="css"
                        name="agreement"
                        ref={checkbox2}
                      />

                      <label htmlFor="css">엔포커 이용약관 동의 (필수)</label>
                      <span
                        onClick={() => setModalLogout(true)}
                        className="terms_open_modal"
                      >
                        보기
                      </span>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="javascript"
                        name="agreement2"
                        ref={checkbox3}
                      />
                      <label htmlFor="javascript">
                        개인정보 수집 및 이용 동의 (필수)
                      </label>
                      <span
                        onClick={() => setModalLogout(true)}
                        className="terms_open_modal"
                      >
                        보기
                      </span>
                    </div>
                    <div className="form-group">
                      <input type="checkbox" id="java" ref={checkbox4} />
                      <label htmlFor="java">
                        홍보성 이메일 수신 동의 (선택)
                      </label>
                      <span
                        onClick={() => setModalLogout(true)}
                        className="terms_open_modal"
                      >
                        보기
                      </span>
                    </div>
                  </form>
                </div>
                <div className="alert_text_002">{message}</div>
                <button className="enter_btn" onClick={handleClick}>
                  동의
                </button>
              </div>
            </div>
          </div>
        </section>
        <Modal
          show={modalLogout}
          animation={false}
          centered
          size="lg"
          dialogClassName="update-modal-width"
          backdrop="static"
          className="terms_modal"
          onHide={() => setModalLogout(false)}
        >
          <Modal.Header>
            <Modal.Title>엔플레이샵 이용약관</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form autoComplete="off">
              <div className="modal_inner_text">
                제 1장 총칙 제1조 목적 이 약관은 에스제이이앤엠(주)(이하
                “회사”)가 제공하는 게임 및 제반 서비스의 이용과 관련하여 회사와
                회원 간의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을
                목적으로 합니다. 제2조 용어의 정의 1. 이 약관에서 사용하는
                용어의 정의는 다음과 같습니다. (1) "게임서비스"라 함은 구현되는
                단말기(PC, TV, 휴대형단말기 등의 각종 유무선 장치를 포함)와
                상관없이 회사가 회원에게 제공하는 제반 서비스를 의미합니다. (2)
                "회원"이라 함은 회사의 게임서비스에 접속하여 이 약관에 따라
                회사와 이용계약을 체결 하고 회사가 제공하는 게임서비스 이용
                자격을 부여받은 자를 의미합니다. (3) “아이디(ID)”라 함은 회원의
                식별과 게임서비스 이용을 위하여 회원이 정하고 회사가 승인하는
                문자와 숫자의 조합을 의미합니다. (4) “비밀번호”라 함은 회원이
                부여받은 계정(ID)과 일치되는 회원임을 확인하고 회원의 정보 및
                권익보호를 위해 회원 자신이 정하여 비밀로 관리하는 문자 또는
                숫자의 조합을 의미합니다. (5) “계정정보”라 함은 회원의
                아이디(ID), 비밀번호, 성명 등 회원이 회사에 제공한 일반정보 및
                게임이용정보, 이용요금 결제상태 등 생성정보를 통칭합니다. (6)
                “캐릭터”라 함은 회원이 게임서비스의 이용을 위하여 게임 세계
                내에서 회사에서 제공하는 방식에 따라 선정하고 조종하는
                게임데이터를 의미합니다. (7) “유료서비스”라 함은 회원이 회사가
                제공하는 결제 방식을 통해 유료로 구매 후 사용할 수 있는
                서비스로, 각 서비스는 다음 내용으로 제공될 수 있습니다. (8)
                “캐쉬”라 함은 게임서비스를 이용 또는 구매하기 위해 사용되는
                가상의 테이터로서 회원이 대금을 지급하고 유상으로 구입하는 것을
                말합니다. (9) “보너스캐쉬”라 함은 게임서비스를 이용 또는
                구매하기 위해 사용되는 가상의 데이터로서 게임서비스의 효율적
                이용을 위해 회사가 임의로 책정하고 무료로 지급하는 일체의 재산적
                가치가 없는 것을 의미합니다. (10) “충전”이라 함은 회사가 정한
                결제수단을 이용하여 캐쉬를 구매하는 행위를 의미합니다. (11)
                “게시물”이라 함은 회원이 게임서비스를 이용함에 있어
                게임서비스상에 게시한 문자, 문서, 그림, 음성, 영상 또는 이들의
                조합으로 이루어진 모든 정보를 의미합니다. 2. 이 약관에서
                사용하는 용어의 정의는 전항에서 정하는 것을 제외하고는 관계법령
                및 기타 일반적인 상관례에 의합니다. 제3조 회사정보 등의 제공
                회사는 다음 각호의 사항을 엔플레이샵
                초기화면(https://www.nplayshop.com)에 게시하여, 회원이 이를 쉽게
                알 수 있도록 합니다. 다만, 개인정보처리방침과 이 약관은 회원이
                연결화면을 통하여 볼 수 있도록 할 수 있습니다. (1) 상호 및
                대표자의 성명 (2) 영업소 소재지 주소(회원의 불만을 처리할 수
                있는 곳의 주소를 포함한다) 및 전자 우편주소 (3) 전화번호 (4)
                사업자등록번호, 통신판매업 신고번호 (5) 개인정보처리방침 (6)
                서비스 이용약관 제4조 약관의 명시와 개정 1. 회사는 이 약관의
                내용을 이용자가 쉽게 알 수 있도록 엔플레이샵
                초기화면(https://www.nplayshop.com)에 게시하거나 연결화면을 통해
                제공하는 방법으로 회원에게 공지합니다. 2. 회사는 회원이
                고객센터를 통해 회사와 이 약관의 내용에 관하여 질의 및 응답을 할
                수 있도록 조치를 취합니다. 3. 회사는 게임서비스를 이용하고자
                하는 자(이하 ‘이용자’라 함)가 약관의 내용을 쉽게 알 수 있도록
                작성하고 약관에 동의하기에 앞서 약관에 정해져 있는 내용 중
                청약철회, 과오납금의 환급, 계약해제·해지, 회사의 면책사항 및
                회원에 대한 피해보상 등과 같은 중요한 내용을 이용자가 쉽게
                이해할 수 있도록 굵은 글씨 등으로 처리하거나 별 도의 연결화면
                또는 팝업화면 등을 통해 제공하고 이용자의 동의를 얻도록 합니다.
                4. 회사는 “전자상거래 등에서의 소비자보호에 관한 법률”, “약관의
                규제에 관한 법률”, “게임산업진흥에 관한 법률”, “정보통신망
                이용촉진 및 정보보호 등에 관한 법률”, “콘텐츠산업 진흥법” 등
                관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
                5. 회사가 약관을 개정할 경우에는 적용일자 및 개정내용, 개정사유
                등을 명시하여 그 적용일자로부터 최소한 7일 이전(이용자에게
                불리하거나 중대한 사항의 변경은 30일 이전)부터 그 적용일자 경과
                후 상당한 기간이 경과할 때까지 엔플레이샵 초기화면과의
                연결화면을 통해 공지 또는 제14조의 방법을 통해 공지합니다. 6.
                회사가 이 약관을 개정할 경우에는 개정 약관 공지 후 개정 약관의
                적용에 대한 회원의 동의 여부를 확인합니다. 단, 개정 약관 공지 시
                회원이 동의 또는 거부의 의사표시를 하지 않으면 승낙한 것으로
                간주 하겠다는 내용도 함께 공지한 경우에는 회원이 약관 시행일까지
                거부 의사를 표시하지 않는다면 개정 약관에 동의한 것으로 봅니다.
                7. 회원이 개정 약관의 적용에 동의하지 않는 경우 회사는 해당
                회원에 대해 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원
                또는 회사는 이용계약을 해지할 수 있습니다. 제5조 운영정책 등
                약관 외 준칙 1. 약관을 적용하기 위하여 필요한 사항과 이용자의
                권익을 보호하고 게임서비스 이용질서를 유지하기 위하여 회사는
                게임서비스 운영정책(이하 “운영정책”)을 정할 수 있습니다. 2.
                회사는 운영정책의 내용을 이용자가 알 수 있도록 서비스 내에
                게시하거나 연결화면을 제공하는 방법으로 이용자가 열람할 수
                있도록 합니다. 3. 이용자의 권리 또는 의무에 중대한 변경을
                가져오거나 약관 내용을 변경하는 것과 동일한 효력이 발생하는
                운영정책 개정의 경우는 제4조의 절차에 따릅니다. 단, 운영정책
                개정이 다음 각 호의 어느 하나에 해당하는 경우에는 제2항의
                방법으로 사전에 공지합니다. (1) 약관에서 구체적으로 범위를
                정하여 위임한 사항을 개정하는 경우 (2) 이용자의 권리의무와 관련
                없는 사항을 개정하는 경우 (3) 운영정책의 내용이 약관에서 정한
                내용과 근본적으로 다르지 않고 이용자가 예측 가능한 범위 내에서
                운영정책을 개정하는 경우 4. 이 약관에서 정하지 아니한 사항이나
                해석에 대해서는 개별 게임서비스 약관 등 및 “전자상거래 등에서의
                소비자보호에 관한 법률”, “약관의 규제에 관한 법률”,
                “게임산업진흥에 관한 법률”, “정보통신망 이용촉 진 및 정보보호
                등에 관한 법률”, “콘텐츠산업 진흥법” 등 관계법령, 일반 상관례에
                따릅니다. 제 2장 이용계약의 체결 제6조 이용신청 및 방법 1.
                회사가 제공하는 서비스를 이용하고자 하는 자는 이 약관의 내용에
                대하여 동의를 하고, 개별 게임 초기 화면이나 게임서비스
                홈페이지(https://www.nplayshop.com)에서 제공하는 이용신청서를
                작성하는 방법으로 이용신청을 하여야 합니다. 2. 이용자는 이용신청
                시 회사에서 요구하는 제반 정보를 제공하여야 합니다. 3. 이용자는
                제1항의 이용 신청 시 본인의 실명 및 실제 정보를 기재하여야
                합니다. 실명 또는 식별정보를 허위로 기재하거나 타인의 명의를
                도용한 경우 회원은 이 약관에 의한 회원의 권리를 주장할 수 없고,
                회사는 환급 없이 이용계약을 취소하거나 해지할 수 있습니다. 4.
                청소년(18세 미만의 자로서 “초·중등교육법” 제2조의 규정에 의한
                고등학교에 재학 중인 학생을 포함한다)이 이용신청을 할 경우에는
                법정대리인의 동의를 얻어야 하고, 구체적인 동의절차는
                “게임산업진흥에 관 한 법률” 및 시행령에 따라 회사가 제공하는
                방법에 따르도록 합니다.
              </div>

              <div className=" w-100 mt-3 text-center">
                <button
                  onClick={() => setModalLogout(false)}
                  type="button"
                  className="btn btn-danger terms_modal_btn"
                >
                  확인
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default ConnectWallet;
