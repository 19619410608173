import React from "react";

import Logo from "../../assets/images/footer_logo.svg";

import FooterImg from "../../assets/images/footer_img.png";
import dayjs from "dayjs";


function Footer() {
  const now = dayjs();
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 footer_logo">
              <img src={Logo} alt="Logo" className="mb-2" />
            </div>

            <div className="col-lg-9">
              <ul className="footer_text footer_margin">
                <li><a href="https://www.nplayshop.com/Help/policy" target="_blank">이용약관</a></li>
                <li><a href="https://www.nplayshop.com/Help/privacy" target="_blank">개인정보처리방침</a></li>
                <li><a href="https://www.nplayshop.com/Help/youth" target="_blank">청소년보호정책</a></li>
                <li><a href="https://www.nplayshop.com/Customer/notice" target="_blank">고객센터</a></li>
              </ul>
              <ul className="footer_text">
                <li>(주)플레이샵컴퍼니 대표이사 이준관</li>
                <li>경기도 용인시 수지구 수지로342번길 34, 4층 4F32호</li>
              </ul>
              <ul className="footer_margin">
                <li>사업자등록번호 515-87-033  </li>
                <li>고객센터 이메일 : <span> support@playshopcompany.com</span></li>
              </ul>
              <div className="copyright_text">
              Copyright © {now.format('YYYY')}. Playshopcompany Limited All Rights Reserved.
              </div>
              <img src={FooterImg} alt="" />
            </div>
           
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
