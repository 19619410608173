import axios from 'axios'
import { loaderStore } from '../store/loader.js';
import { toast } from 'react-toastify'
import { clearTemporaryStorage } from 'helpers/helpers'

const baseURL = process.env.REACT_APP_API_URL;

const axiosClient = axios.create({
	baseURL: baseURL,
});

const axiosFile = axios.create({
	baseURL: baseURL,
});

export async function PGET(URL, payload = {}, settings = {}) {
	const showLoader = loaderStore.getState().showLoader;
	const hideLoader = loaderStore.getState().hideLoader;
	var params = "";
	if (Object.entries(payload).length > 0) {
		params = getPath(payload, URL);
	}

	if (settings.loader) {
		showLoader();
		const data = await axiosClient.get(
			URL + params,
			{ headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
		)
			.catch(error => {
				httpStatusChecker(error)
			})
		hideLoader();
		return data.data
	} else {
		const data = await axiosClient.get(
			URL + params,
			{ headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
		)
		return data.data
	}
}

export async function GET(URL, payload = {}, settings = {}) {
	const showLoader = loaderStore.getState().showLoader;
	const hideLoader = loaderStore.getState().hideLoader;
	var params = "";
	if (Object.entries(payload).length > 0) {
		params = getPath(payload);
	}

	if (settings.loader) {
		showLoader();
		const data = await axiosClient.get(
			URL + params,
			{ headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
		)
			.catch(error => {
				httpStatusChecker(error)
				return error
			})
		hideLoader();
		if (data?.response?.status >= 300) {
			return data
		}
		return data?.data
	} else {
		const data = await axiosClient.get(
			URL + params,
			{ headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
		).catch(error => {
			httpStatusChecker(error)
			return error
		})
		return data.data
	}
}

export async function POST(URL, payload, settings = { loader: true }) {
	const showLoader = loaderStore.getState().showLoader;
	const hideLoader = loaderStore.getState().hideLoader;
	var params = "";

	// let jsonData = JSON.stringify(payload);
	if (settings.loader) {
		showLoader();
	}
	try {
		const data = await axiosClient.post(
			URL + params,
			payload,
			{ headers: { Authorization: localStorage.getItem('token') === null ? '' : 'Bearer ' + localStorage.getItem('token') } }
		)
		if (data && data.data.code === 200) {
		
			toast.dismiss();
			toast.success('SUCCESS')
		} else{
			toast.error('ERROR')
		}
		return data.data;
	} catch (error) {
		httpStatusChecker(error)
		return error;
	} finally {
		if (settings.loader) {
			hideLoader();
		}
	}
}

function getPath(payload, url) {
	let iterations = Object.entries(payload).length;
	var pathArr = "?";
	if (url)
		url.includes("?") ? pathArr = '&' : pathArr = '?'

	for (let key in payload) {
		if (!--iterations) {
			pathArr += key + "=" + payload[key];
		} else {
			pathArr += key + "=" + payload[key] + "&";
		}
	}
	return pathArr;
}

function httpStatusChecker(error) {
	toast.dismiss();

	if (error?.response?.status === 400) {
		toast.error(error.response.data.message)
		return;
	}
	if (error?.response?.status === 401) {
		clearTemporaryStorage()
		if (process.env.NODE_ENV === 'production') {
			window.location.href = "https://cabinet.mison.uz/#/login";
		} else {
			window.location.href = process.env.REACT_APP_URL + "/#/auth/login";
		}

		toast.error('로그인 또는 비밀번호가 올바르지 않습니다.')
		return;
	}
	if (error?.response?.status === 404) {
		toast.error('Not Found')
		return;
	}
	if (error?.response?.status === 415) {
		toast.error('Error')
		return;
	}
	if (error?.response?.status === 500) {
		var message = ""
		if (error.response.data.file) {
			message += error.response.data.file
		}
		if (error.response.data.line) {
			message += error.response.data.line
		}
		if (error.response.data.message) {
			message += error.response.data.message
		}
		toast.error(message)
		return;
	}
	if (!error.status) { // если нету интернета то выходит эта ошибка
		return
	}
}

function httpStatusCheckerGuess(error) {
	if (error?.response?.status === 400) {
		toast.error(error.response.data.message)
	}
	if (error?.response?.status === 401) {
		toast.error('로그인 또는 비밀번호가 올바르지 않습니다.')
	}
}