import React from 'react'
import { Outlet } from 'react-router-dom';
import Header from "./header/Header";
import Footer from './footer/Footer';

function Layout() {


  return (
    <div id="layout-wrapper">
      <Header />

      <div className="main-content">
        <div className="page-content">
          <Outlet />
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Layout