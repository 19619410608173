import { create } from 'zustand';

export const defaultVerificationInfo = {
  id: '',
  name: '',
  dob: '',
  ci: '',
  di: '',
  sex: '',
  phone: ''
};

export const useVerificationInfoStore = create((set) => ({
  verificationInfo: defaultVerificationInfo,
  setVerificationInfo: (verificationInfo) => set({ verificationInfo }),
  clearVerificationInfo: () => set({ verificationInfo: defaultVerificationInfo }),
}));